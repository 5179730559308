import { APP_TYPE, URL_APP_TYPE } from 'consts';
import type { ValueOf } from 'types';

import logo_cx from '../../../assets/images/logo_cx.svg';
import logo_hx from '../../../assets/images/logo_hx.svg';
import logo_fls from '../../../assets/images/logo_fls.svg';
import logo_cxna from '../../../assets/images/logo_cxna.svg';
import logo_cxeuro from '../../../assets/images/logo_cxeuro.svg';
import logo_cp from '../../../assets/images/logo_cp.svg';
import logo_carrier from '../../../assets/images/logo_carrier.svg';
import logo_broker from '../../../assets/images/logo_broker.svg';
import integra_logo from '../../../assets/images/integra-logo.svg';
import logiquanta_logo from '../../../assets/images/logiquanta-logo.svg';

export const getLogoSrc = (app: ValueOf<typeof URL_APP_TYPE>) => {
    switch (app) {
        case URL_APP_TYPE[APP_TYPE.CX]:
            return logo_cx;
        case URL_APP_TYPE[APP_TYPE.HX]:
        case URL_APP_TYPE[APP_TYPE.SF]:
            return logo_hx;
        case URL_APP_TYPE[APP_TYPE.FLS]:
            return logo_fls;
        case URL_APP_TYPE[APP_TYPE.CXNA]:
        case URL_APP_TYPE[APP_TYPE.CXNA_EXPERDITER]:
            return logo_cxna;
        case URL_APP_TYPE[APP_TYPE.CXEURO]:
            return logo_cxeuro;
        case URL_APP_TYPE[APP_TYPE.CUSTOMER_PORTAL]:
            return logo_cp;
        case URL_APP_TYPE[APP_TYPE.CXNA_CARRIER]:
            return logo_carrier;
        case URL_APP_TYPE[APP_TYPE.CXNA_BROKER]:
            return logo_broker;
        case URL_APP_TYPE[APP_TYPE.INTEGRA]:
        case URL_APP_TYPE[APP_TYPE.LENDER_PORTAL]:
            return integra_logo;
        case URL_APP_TYPE[APP_TYPE.LOGIQUANTA]:
            return logiquanta_logo;
        default:
            return logo_cx;
    }
};
